<template>
  <div class="travel">
    <TextureWrapper cssStyle="pink">
      <Nav />
      <div class="travel__wrapper">
        <div class="travel__copy">
          <h1 class="travel__title">Privacy policy</h1>

          <p>
          Sheffield City Centre BID Limited (‘Sheffield BID’) of Sheffield Technology Parks, Cooper Buildings, Arundel Street, Sheffield S1 2NS confirms that we comply with data protection legislation.
          </p>

          <p>
          <strong>
            Your Personal Data:
          </strong>
          </p>

          <p>
          <strong>
            What we need
          </strong>
          </p>
          <p>
          Sheffield BID is the ‘Controller’ of the personal data you provide to us through the website sheffieldbricktropolis.com. To process this data, we need your explicit consent. You can withdraw this consent at any time.
          </p>

          <p>
          We only collect basic personal data about you which does not include any special types of information. This may however include your name, email, IP address, page visits and site usage statistics.
          </p>

          <p>
          <strong>
            Why we need it
          </strong>
          </p>
          <p>
          We need to know your basic personal data to fulfil the request you have made to be kept informed about Sheffield Christmas Trail. We will not collect any personal data from you that we do not need to provide and oversee this service to you.
          </p>

          <p>
          <strong>
            What we do with it
          </strong>
          </p>
          <p>
          All the personal data we process is handled within the United Kingdom by our staff in Sheffield, South Yorkshire. However, for the purposes of IT hosting and maintenance this information is located on servers within the European Union. We also use an automated system for email marketing. This system – MailChimp – is located outside of the European Union. It is however certified through the EU-US Privacy Shield Framework and subscriber data is processed securely and appropriately in line with the legal requirements of the European Union.
          </p>

          <p>
          No third parties have access to your personal data unless the law allows them to do so.
          </p>

          <p>
          We have a Data Protection policy in place to oversee the effective and secure processing of your personal data.
          </p>

          <p>
          <strong>
            How long we keep it
          </strong>
          </p>
          <p>
          Your information we use for marketing purposes will be kept with us until you notify us that you no longer wish to receive this information. In any event we will not retain your personal information for any longer than is needed, typically no later than three months after the event has finished.
          </p>

          <p>
          <strong>
            What we would also like to do with it
          </strong>
          </p>

          <p>
          We will also your name and email address to ask you for feedback within one month of the event concluding.
          </p>
        </div>
      </div>
    </TextureWrapper>
    <TextureWrapper cssStyle="">
      <Footer />
    </TextureWrapper>
  </div>
</template>

<script>
import TextureWrapper from '@/components/TextureWrapper'
import GetApp from '@/components/GetApp'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer'

export default {
  name: 'home',
  components: {
    Nav, GetApp, TextureWrapper, Footer
  },
};
</script>

<style lang="scss" scoped>
.travel {
  color: rgb(255, 255, 255);
  &__wrapper {
    z-index: 2;
    @include gridWidth;
    padding-top: 40px;
    @media(min-width: $md) {
      padding-top: 100px;
    }
  }
  &__copy {
    position: relative;
    z-index: 10;
    @media(min-width: $md) {
      width: 60%;
    }
    padding-bottom: vr(4);
  }
  &__title {
    @include heading;
    font-weight: normal;
    font-size: vr(3);
    line-height: vr(2);
  }
}
</style>
