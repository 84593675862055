<template>
  <section class="faqs">
    <Wrapper cssStyle="pink">
      <Nav />
    </Wrapper>
    <Wrapper cssStyle="red">
      <div class="faqs__wrapper">

        <h1 class="faqs__title">FAQs</h1>

        <div class="faqs__col faqs__col-1">

          <div class="faqs__col-text">
            <TextBlock
              cssClass="faqs"
              title="When does the Christmas Trail start?"
              text="The Christmas Trail…the story of Brearley Bear’s Festive Guest will run from 5 December – 24 December 2020."
            />
            <TextBlock
              cssClass="faqs"
              title="What are your opening times?"
              text='<p>Hidden letters are located at venues in the city centre, all of which are accessible between at least 9.30am and 5.30pm Monday to Saturday and 11.00am and 4.00pm Sunday. Venue trading times may differ on Christmas Eve.</p>
              <p>Brearley’s Christmas HQ is open:</p>
              <ul>
                <li>10am – 6pm Monday – Saturday (7pm Thursday)</li>
                <li>11am – 4pm Sunday, and</li>
                <li>10am – 4pm Christmas Eve</li>
              </ul>'
            />
          </div>

          <div class="faqs__col-char">
            <Img src="2020/acorn.png" />
          </div>

        </div>


        <div class="faqs__col faqs__col-2">

          <div class="faqs__col-text">
            <TextBlock
              cssClass="faqs"
              title="Does it cost money to complete the Christmas Trail?"
              text="No, it’s free! This year’s Christmas Trail is a perfect free seasonal activity for families with young children."
            />
            <TextBlock
              cssClass="faqs"
              title="How do I complete the Christmas Trail?"
              text="Pick up a Trail Guide from Brearley’s Christmas HQ next door to the Winter Garden on Surrey Street. Find the Hidden letters that are located at five points in the city centre. Return to Brearley’s Christmas HQ to claim a free Christmas treat for every child. Here, you can also post your completed trail guide in Santa’s Post Box to be in with a chance to win one of five £200 Sheffield Gift Cards."
            />
          </div>

          <div class="faqs__col-char">
            <Img src="2020/acorns-2.png" />
          </div>
        </div>
      </div>
    </Wrapper>
    <Wrapper>
      <Footer />
    </Wrapper>
  </section>
</template>

<script>
import Wrapper from '@/components/TextureWrapper'
import Footer from '@/components/Footer'
import Nav from '@/components/Nav'
import TextBlock from '@/components/TextBlock'
import Vector from '@/components/Vector'
import Img from '@/components/Img'

export default {
  name: 'home',
  components: {
    Nav, Wrapper, Footer, TextBlock, Vector, Img
  },
}
</script>

<style lang="scss" scoped>
.faqs {
  &__title {
    @include heading;
    @include h1;
    color: rgb(255, 255, 255);
    margin-bottom: vr(3);
  }
  &__wrapper {
    @include gridWidth;
    padding-top: 30px;
    @media(min-width: $md) {
      padding-top: 100px;
    }
  }
  &__col {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    flex-direction: column;
    @media(min-width: $md) {
      flex-direction: row;
    }

    &-text {
      width: 100%;
      @media(min-width: $md) {
        width: 60%;
      }
      & > article {
        margin-bottom: vr(2);
      }
    }
    &-char {
      max-width: 200px;
      margin-bottom: vr(3);
    }
    &.faqs__col-1 {
      .faqs__col-char {
        // transform: rotateY(180deg);
      }
    }
    &.faqs__col-2 {
      flex-direction: column;
      @media(min-width: $md) {
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
