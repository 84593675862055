<template>
  <div class="travel">
    <TextureWrapper cssStyle="pink">
      <Nav />
      <div class="travel__wrapper">
        <div class="travel__copy">
          <h1 class="travel__title">Offers</h1>

          <TextBlock
              cssClass="faqs faqs_blue"
              title="10% off at Costa, Pinstone Street"
              text="Show your Red Nose Locator Guide to receive 10% off your order. (Pinstone Street store only)"
          />
          <TextBlock
              cssClass="faqs faqs_blue"
              title="Kids Eat Free at Bella Italia, St Paul’s Place"
              text="This offer entitles you to one free kid’s Piccolo or Grande meal with each purchase of a full priced adult main at Bella Italia Sheffield (St Paul’s only). The offer is valid from 12 noon every day until 24 December 2019. To redeem the offer just present your Red Nose Locator Guide. This offer is not valid in conjunction with other offers or discounts (including set menus, Christmas menus, lunch menu, partner offers or Tesco Clubcard Deal tokens). No maximum party size, but there must be one full priced adult main purchased for each free kid’s meal. For parties over 12, please book in advance. Not valid on delivery or takeaway, including Just Eat, Deliveroo or Uber Eats."
          />

          <TextBlock
              cssClass="faqs faqs_blue"
              title="200 Degrees Coffee, Division Street"
              text="Claim a free hot drink with any cake purchase. To redeem the offer just present your Red Nose Locator Guide."
          />

          <TextBlock
              cssClass="faqs faqs_blue"
              title="Craft &amp; Dough, Campo Lane"
              text="<p>Get up to two free 6&quot; kids pizzas with a choice of any toppings from the menu when purchasing one adult's main meal. Please note this offer is not valid through the Milestone Group app or in conjunction with any other offers or Christmas festive menu. Children must be aged 12 or under. Please show your Red Nose Locator Guide to redeem.</p>"
          />

          <TextBlock
            cssClass="faqs faqs_blue"
            title="Q-Park discounts"
            text="<p>Get a 10% discount at the following Q-Park venues when you book online. Use the promo code SHEFFXMAS10 for:</p>
            <ul>
              <li>Rockingham Street</li>
              <li>Sheffield Station</li>
              <li>Charles Street</li>
            </ul>
            <p>Get a 25% discount at the following Q-Park venues when you book online. Use the promo code SHEFFXMAS25 for:</p>
            <ul>
              <li>Durham Road</li>
              <li>Riverside</li>
              <li>Castlegate</li>
            </ul>
            <p>Pre-book car parking at <a href='https://www.q-park.co.uk/en-gb/cities/sheffield/'>Q Park Sheffield</a> to receive your discount.</p>"/>

        </div>
      </div>
    </TextureWrapper>
    <TextureWrapper cssStyle="">
      <Footer />
    </TextureWrapper>
  </div>
</template>

<script>
  import TextureWrapper from '@/components/TextureWrapper'
  import TextBlock from '@/components/TextBlock'
  import GetApp from '@/components/GetApp'
  import Nav from '@/components/Nav.vue'
  import Footer from '@/components/Footer'

  export default {
    name: 'home',
    components: {
      Nav,
      GetApp,
      TextureWrapper,
      TextBlock,
      Footer
    },
  };

</script>

<style lang="scss" scoped>
.travel {
  color: rgb(255, 255, 255);
  &__wrapper {
    z-index: 2;
    @include gridWidth;
    padding-top: 40px;
    @media(min-width: $md) {
      padding-top: 100px;
    }
  }
  &__copy {
    @media(min-width: $md) {
      width: 100%;
    }
    padding-bottom: vr(4);
  }
  &__title {
    @include heading;
    font-weight: normal;
    font-size: vr(3);
    line-height: vr(2);
    margin-bottom: vr(3);
  }
  &__copy {
    & > article {
      margin-bottom: vr(1.5);
      width: 100%;
      z-index: 300;
      @media(min-width: $md) {
        margin-bottom: vr(3);
        width: 80%;
        margin-left: auto;
        align-self: flex-end;
        &:nth-child(2n+2) {
          align-self: flex-start;
          margin-left: 0;
        }
      }
      @media(min-width: $lg) {
        width: 70%
      }
      @media(min-width: $xl) {
        width: 80%
      }
    }
  }
}
</style>
