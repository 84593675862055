<template>
  <section class="santa_help">
    <div class="santa_help__wrapper">
      <div class="left">
        <TextBlock class="santa_help__text" v-in-viewport.once
                   text="<p>The Sheffield Christmas Trail … Brearley Bear’s Festive Guest will run from Saturday, 5 December to Thursday, 24 December 2020.</p>
                   <p>Brearley’s Christmas HQ is open: <br />
                   10am – 6pm Monday – Saturday (7pm Thursday) <br />
                   11am – 4pm Sunday, and <br />
                   10am – 4pm Christmas Eve</p>
                   <p>Brearley’s HQ closes at 4pm on Christmas Eve so please allow enough time to finish the trail and collect the children’s special gift!</p>
                   <p>When you visit Brearley’s HQ we are adhering to social distancing, providing a safe and secure environment. Only a single household will be able enter at any one time (a maximum of two adults plus children) and, unless exempt, we ask you to wear a face mask in line with government guidelines and make use of Brearley’s hand sanitiser.</p>"
                   cssClass="blue"
        />
        <!-- <GetApp cssStyle="blue" class="get_app" v-in-viewport.once /> -->
      </div>
      <div class="right">
        <Img src="2020/acorn.png" class="santa_svg" v-in-viewport.once />
      </div>
    </div>
  </section>
</template>

<script>
import TextBlock from '@/components/TextBlock'
import Vector from '@/components/Vector.vue'
import GetApp from '@/components/GetApp.vue'
import Img from '@/components/Img'

export default {
  name: 'SantaHelp',
  components: { GetApp, TextBlock, Vector, Img }
}
</script>

<style lang="scss" scoped>
.santa_help {
  display: flex;
  margin-top: vr(2);
  padding-bottom: vr(2);
  @media(min-width: $md) {
    margin-top: vr(5);
    padding-bottom: vr(5);
  }
  &__wrapper {
    @include gridWidth;
    display: flex;
    flex-direction: column;
    @media(min-width: $md) {
      align-self: center;
      flex-direction: row;
    }
  }
  .left {
    @media(min-width: $md) {
      width: 65%;
    }
    .santa_help__text {
      margin-bottom: vr(2);
      @media(min-width: $md) {
        margin-bottom: vr(2);
      }
      @include slideIn($fromRight: false)
    }
  }
  .get_app {
    @include slideIn($fromRight: false, $delay: 200ms);
  }
  .right {
    margin-left: auto;
    padding-top: 10%;
    .santa_svg {
      float: right;
      width: 60%;
      max-width: 400px;
      @include slideIn;
    }
    @media(max-width: $tb-max) {
      width: 60%;
      margin: 0 auto;
      margin-top: 40px;
    }
  }
}
</style>
