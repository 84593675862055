<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <a title="Sheffield BID website" href="https://www.sheffieldbid.com" target="_blank">
        <LogoAndBid class="logo_bid" />
      </a>
      <div class="footer__social">
        <div class="footer__social-email">
          <Img src="mail.png" class="svg mail" />
          <a href="https://sheffieldbid.us12.list-manage.com/subscribe?u=99eb649517c5972c26c4f4f3d&id=3ef8a19628" target="_blank">Sign up for email updates</a>
        </div>
        <div class="footer__social-twitter">
          <Img src="bear_head.png" class="svg bear" />
          <a href="https://twitter.com/BrearleyBear" target="_blank">Check out Brearley’s Twitter account</a>
        </div>
      </div>
      <div class="footer__links">
        <div>
          Get in touch: <br /> <a href="mailto:enquiries@sheffieldbid.com">enquiries@sheffieldbid.com</a>
        </div>
        <div>
          <router-link to="/privacy">Privacy Policy</router-link>
        </div>
      </div>
      <div class="bep">
        <a href="https://theblackeyeproject.co.uk" title="Black Eye Project - Advertising, Design, Digital, Marketing">Site by Black Eye Project</a>
      </div>
    </div>
  </footer>
</template>

<script>
import LogoAndBid from '@/components/LogoAndBid'
import Vector from '@/components/Vector'
import Img from '@/components/Img'

export default {
  name: 'Footer',
  components: { LogoAndBid, Vector, Img }
}
</script>

<style lang="scss" scoped>
.footer {
  padding: vr(2) vr(1);
  padding: vr(2) vr(1);
  @media(min-width: $md) {
    padding: vr(4) vr(4);
    padding: vr(4) vr(4);
  }
  background: #2E4825;
  color: rgb(255, 255, 255);
  a {
    color: rgb(255, 255, 255);
  }
  &__wrapper {
    @include gridWidth;
    display: flex;
    position: relative;
    flex-direction: column;
    font-size: vr(0.75);
    @media(min-width: $md) {
      flex-direction: row;
      align-items: flex-end;
    }
    justify-content: space-between;
    > * {
      @media(max-width: $tb-max) {
        margin-bottom: vr(1.5);
      }
    }
  }
  .bep {
    @media(min-width: $md) {
      position: absolute;
      bottom: -70px;
      width: 100%;
      text-align: center;
    }
  }
  .logo_bid {
    width: 100px;
    @media(min-width: $md) {
      width: 200px;
    }
  }
  &__social {
    display: flex;
    flex-direction: column;
    align-content: center;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: vr(1);
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        .svg {
          transform: rotateZ(20deg);
        }
      }
    }
    a {
      //border-bottom: dashed 1px;
      border-bottom: solid 1px rgba(255, 255, 255, 0);
      &:hover {
        border-bottom: solid 1px rgba(255, 255, 255, 0.9);
      }
    }
    .svg {
      display: none;
      @media(min-width: $md) {
        display: block;
        transition: transform 200ms linear;
      }
      width: 40px;
      margin-right: vr(1);
      &.mail {
        fill: rgb(255, 255, 255);
      }
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    line-height: vr(1);
    div {
      margin-bottom: vr(1);
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      display: inline-block;
      width: auto;
      border-bottom: solid 1px;
    }
  }
}
</style>
