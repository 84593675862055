import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import About from './views/About.vue';
import Travel from './views/Travel.vue';
import Covid19 from './views/COVID-19.vue';
import Faqs from './views/Faqs.vue';
import Offers from './views/Offers.vue';
import Privacy from './views/Privacy.vue'
import Competition from './views/Competition.vue'

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/covid-19',
      name: 'covid-19',
      component: Covid19,
    },
    {
      path: '/travel',
      name: 'travel',
      component: Travel,
    },
    {
      path: '/faqs',
      name: 'faqs',
      component: Faqs,
    },
    {
      path: '/offers',
      name: 'offers',
      component: Offers,
    },
    // {
    //   path: '/competition',
    //   name: 'competition',
    //   component: Competition,
    // },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy,
    },
    // 404 type catchall and redirect - not a true 404!!
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 1)
    })
  }
});
