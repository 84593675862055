<template>
  <div class="travel">
    <TextureWrapper cssStyle="pink">
      <Nav />
      <div class="travel__wrapper">
        <div class="travel__copy">
          <h1 class="travel__title">
            COVID-19 Infomation
          </h1>

          <TextBlock
            cssClass="faqs faqs_blue"
            title="Stay COVID-safe"
            text="<p>Please remember that Sheffield and the wider South Yorkshire will be placed into the Very High alert level of Tier 3 from Wednesday, 2 December. You must wear a face covering, unless exempt, in any indoor setting and should not meet socially even outdoors with anybody you do not live with, or who is not in your support bubble.</p>
            <p>Please stay safe when doing the Christmas Trail and adhere to government guidelines. Here’s what you need to know for <a href='https://www.gov.uk/guidance/local-restriction-tiers-what-you-need-to-know#very-high-alert'>Tier 3</a>.</p>"/>

        </div>
        </div>
    </TextureWrapper>
    <TextureWrapper cssStyle="">
      <Footer />
    </TextureWrapper>
</div>
</template>


<script>
import TextureWrapper from '@/components/TextureWrapper'
import TextBlock from '@/components/TextBlock'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer'

export default {
  name: 'home',
  components: {
    Nav, TextureWrapper, Footer, TextBlock
  },
};
</script>

<style lang="scss" scoped>
.travel {
  color: rgb(255, 255, 255);
  &__wrapper {
    z-index: 2;
    @include gridWidth;
    padding-top: 40px;
    @media(min-width: $md) {
      padding-top: 100px;
    }
  }
  &__copy {
    @media(min-width: $md) {
      width: 100%;
    }
    padding-bottom: vr(4);
  }
  &__title {
    @include heading;
    font-weight: normal;
    font-size: vr(3);
    line-height: vr(2);
    margin-bottom: vr(3);
  }
  &__copy {
    & > article {
      margin-bottom: vr(1.5);
      width: 100%;
      z-index: 300;
      @media(min-width: $md) {
        margin-bottom: vr(3);
        width: 80%;
        margin-left: auto;
        align-self: flex-end;
        &:nth-child(2n+2) {
          align-self: flex-start;
          margin-left: 0;
        }
      }
      @media(min-width: $lg) {
        width: 70%
      }
      @media(min-width: $xl) {
        width: 80%
      }
    }
  }
}
</style>