<template>
  <div class="travel">
    <TextureWrapper cssStyle="pink">
      <Nav />
      <div class="travel__wrapper">
        <div class="travel__copy">
          <h1 class="travel__title">Competition Details</h1>


          <TextBlock
            cssClass="faqs faqs_blue"
            title="Complete the Sheffield Christmas Trail and you could be going to visit Santa in Lapland in Christmas 2019!"
            text="<p>Find all the presents on Brearley’s lost Christmas list on the app or by completing a Little Book of Clues and entry slip to be entered into a draw to win a day trip to Lapland next Christmas. Three runners up will receive a £200 Sheffield Gift Card.</p>
            <h3>The small print</h3>
            <p><strong>How to enter: </strong> Complete the trail in full using the trail app and you’ll automatically be entered into the prize draw. Complete the trail in full using the Little Book of Clues and be sure to fill in a prize-draw entry card at Santa’s Secret Sorting Office – which is where the trail officially ends and the children can collect a special prize.</p>
            <p><strong>About the prize:</strong> Win a one-day trip to Lapland in Finland for a family of five. Leaving the UK from a designated airport at around 8am, breakfast will be served on your flight. It takes 3.5 hours to fly to Lapland and on arrival, you’ll be provided with thermal snow suits and boots to keep you warm during the day. Then it’s time to head to a beautiful location set beside a frozen lake shore by snowmobile and sleigh. Once at the venue you can relax and enjoy the magic of this amazing winter wonderland. Learn to drive a snowmobile (suitable for adults and children alike). Of course, children always love to try some tobogganing and Santa has special toboggans available. Enter the snow igloo with ice sculptures and furniture made of ice. Sample Santa’s traditional hot berry juice. No journey to Lapland would be complete without experiencing a traditional sleigh ride pulled by Santa’s faithful reindeer (or huskies if the reindeers are in training for Christmas Eve). And then its time to meet Santa Claus. A sleigh will transport you to his home in the forest where each child will receive a gift from Santa himself. Lunch will be provided as part of your itinerary. And when the dream of meeting Santa has been realised, it’s finally time to head home, taking the short journey to Enontekio Airport, leaving Santa and his Elves to continue their important duties. Dinner will be served during your return flight, then it’s time to rest, relax and remember your fun-filled time in Lapland ... courtesy of Sheffield city centre!</p>
            <p><strong>Terms and conditions apply:</strong> This festive prize is available for Christmas 2019. Entrants must have completed the Sheffield Christmas Trail in full. Entrants must be aged 18 or over to claim the prize. For those under 18 years of age, the prize must be claimed by a parent or legal guardian. The prize is based on a family of five assuming two adults and three children between the ages of 2 to 11 years but not older than 15 years of age. Please note that this trip may be unsuitable for children under 2 years of age due to extreme temperatures. The approximate value of this prize is £2,000. There is no cash alternative. Winners must be willing to travel from a designated UK airport and designated date subject to availability of dates and must arrange their own travel insurance including winter sports cover.</p>"
          />

        </div>
      </div>
    </TextureWrapper>
    <TextureWrapper cssStyle="">
      <Footer />
    </TextureWrapper>
  </div>
</template>

<script>
import TextureWrapper from '@/components/TextureWrapper'
import TextBlock from '@/components/TextBlock'
import GetApp from '@/components/GetApp'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer'

export default {
  name: 'Competition',
  components: {
    Nav, GetApp, TextBlock, TextureWrapper, Footer
  },
};
</script>

<style lang="scss" scoped>
h2,h3 {
  margin-bottom: vr(2);
}
.travel {
  color: rgb(255, 255, 255);
  &__wrapper {
    z-index: 2;
    @include gridWidth;
    padding-top: 40px;
    @media(min-width: $md) {
      padding-top: 100px;
    }
  }
  &__copy {
    position: relative;
    z-index: 10;
    @media(min-width: $md) {
      width: 60%;
    }
    padding-bottom: vr(4);
  }
  &__title {
    @include heading;
    font-weight: normal;
    font-size: vr(3);
    line-height: vr(2);
    margin-bottom: vr(3);
  }
}
</style>
