<template>
  <div class="vector">
    <svg :viewBox="viewbox" :xmlns="xmlns" v-html="dummy.innerHTML" :preserveAspectRatio="$props.preserveAspectRatio" />
  </div>
</template>
<script>
//require('innersvg-polyfill')
export default {
  name: 'CSVG',
  props: {
    src: {
      type: String,
      required: true,
    },
    preserveAspectRatio: {
      type: String,
      default: () => 'xMaxYMax',
    }
  },
  data () {
    return {
      canvas: document.createElement('div')
    }
  },
  created () {
    this.canvas.innerHTML = this.asset
  },
  computed: {
    asset () {
      //return require(`!!svg-inline-loader!@/assets/images/${this.$props.src}`)
      return require(`@/assets/images/${this.$props.src}`)
    },
    dummy () {
      return this.canvas.children[0]
    },
    viewbox () {
      return this.dummy.getAttribute('viewBox') || this.dummy.getAttribute('viewbox')
    },
    xmlns () {
      return this.dummy.getAttribute('xmlns')
    }
  }
};
</script>
<style lang="scss" scoped>
.vector {
  svg {
    width: 100%;
    height: auto;
  }
}
</style>
