<template>
  <div>
    <img :src="`${images.src}`" :srcset="images.srcSet" :title="$props.title" :alt="$props.title" />
  </div>
</template>
<script>
export default {
  name: 'Img',
  props: {
    src: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  created () {
    //console.log(this.$props.src)
  },
  computed: {
    images () {
      // Automatically generates image srcset up to 4096px wide
      return require(`responsive-loader?max=4096&min=240&steps=12&quality=80!@/assets/images/${this.$props.src}`)
    }
  }
}
</script>
<style lang="scss" scoped>
div {
  img {
    width: 100%;
    height: auto;
  }
}

</style>
