<template>
  <section :class='`textureback ${style}`'>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'TextureBack',
  props: { cssStyle: String },
  computed: {
    style () {
      return this.$props.cssStyle ? `textureback__${this.$props.cssStyle}` : ''
    }
  }
}
</script>

<style lang="scss">
.textureback {
  overflow-x: hidden;
  * {
    z-index: 2;
  }
  &__pink {
    @include textureback('texture_3840.jpg')
  }
  &__blue {
    @include textureback('texture_green_3840.jpg')
  }
  &__green {
    @include textureback('texture_green_alt_3840.jpg')
  }
  &__red {
    @include textureback('texture_red_3840.jpg', false)
  }
}
</style>
