<template>
  <label :class='`hamburger ${cssStyle}`'>
    <div class="bit-1"></div>
    <div class="bit-2"></div>
    <div class="bit-3"></div>
  </label>
</template>
<script>
export default {
  name: 'Hamburger',
  props:
    {
      checked: Boolean
    }
  ,
  data () {
    return {
    }
  },
  computed: {
    cssStyle () {
      return this.$props.checked ? "hamburger__checked" : ''
    }
  }
}
</script>
<style lang="scss">
.hamburger {
  position: relative;
  display: block;
  width: vr(1.5);
  height: vr(1.5);
  z-index: 999;
  cursor: pointer;
  @media(min-width: $md) {
    display: none;
  }
  .bit-1 {
    transition-timing-function: ease-out;
    &::before {
      content: '';
      left: vr(0.25);
      top: vr(0.25);
      position: absolute;
      width: vr(0.5);
      transform-origin: left bottom;
      height: vr(0.15);
      background: #2E313C;
      transition: transform 0.2s, top 0.2s;
    }
    &::after {
      content: '';
      position: absolute;
      right: vr(0.25);
      top: vr(0.25);
      width: vr(0.5);
      transform-origin: right bottom;
      height: vr(0.15);
      background: #2E313C;
      transition: transform 0.2s, top 0.2s;
    }
  }
  .bit-2 {
    position: absolute;
    width: vr(1);
    top: 50%;
    left: 50%;
    height: vr(0.15);
    background: #2E313C;
    transform: translate(-50%, -50%);
    transition: transform 0.2s 0.2s, width 0.2s 0.3s;
  }
  .bit-3 {
    &::before {
      content: '';
      position: absolute;
      bottom: vr(0.25);
      left: vr(0.25);
      width: vr(0.5);
      transform-origin: left top;
      height: vr(0.15);
      background: #2E313C;
      transition: transform 0.2s, bottom 0.2s;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: vr(0.25);
      right: vr(0.25);
      width: vr(0.5);
      transform-origin: right top;
      height: vr(0.15);
      background: #2E313C;
      transition: transform 0.2s, bottom 0.2s;
    }
  }
}
.hamburger__checked {
  position: fixed;
  right: vr(1.5);
  top: vr(2.5);
  .bit-1 {
    &::before {
      background: #fff;
      top: vr(0.2);
      height: vr(0.15);
      width: vr(0.6);
      transform: rotate(45deg);
      transform-origin: left bottom;
      transition: transform 0.2s 0.2s, width 0.2s 0.2s, top 0.2s 0.2s;
    }
    &::after {
      background: #fff;
      top: vr(0.2);
      height: vr(0.15);
      width: vr(0.6);
      transform: rotate(-45deg);
      transform-origin: right bottom;
      transition: transform 0.2s 0.2s, width 0.2s 0.2s, top 0.2s 0.2s;
    }
  }
  .bit-2 {
    background: #fff;
    width: vr(0.15);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: transform 0.2s 0.2s, width 0.2s;
  }
  .bit-3 {
    &::before {
      background: #fff;
      bottom: vr(0.2);
      width: vr(0.6);
      transform: rotate(-45deg);
      transform-origin: left top;
      transition: transform 0.2s 0.2s, width 0.2s 0.2s, bottom 0.2s 0.2s;
    }
    &::after {
      background: #fff;
      bottom: vr(0.2);
      width: vr(0.6);
      transform: rotate(45deg);
      transform-origin: right top;
      transition: transform 0.2s 0.2s, width 0.2s 0.2s, bottom 0.2s 0.2s;
    }
  }
}
#hamburger__target:checked ~ .menu__target {
  @media(max-width: $tb-max) {
    transform: translateY(0);
    top: vr(5);
    box-shadow: 0px 10px 10px 2px rgba(0,0,0,0.2);
    overflow-x: hidden;
    z-index: 5;
    @media(min-width: $md) {
      top: 0;
    }
    .header__nav-link {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
</style>
