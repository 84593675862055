<template>
  <article :class='`help_him__cta ${mainTheme}`'>
    <h1 class="text__title" v-if="$props.title">{{ $props.title }}</h1>
    <div class="text__copy" v-html="$props.text"></div>
    <div class="text__download" v-if="$props.bookOfClues">
      <a target="_blank" href="https://sheffield-christmas-trail.s3-eu-west-1.amazonaws.com/A4_trail.pdf" >
        <Img class="book" src="2020/trail.jpg" />
        <span>
          Download the Trail Guide here (PDF, 1.4MB)
        </span>
      </a>
    </div>
  </article>
</template>

<script>
import Img from '@/components/Img'

export default {
  name: 'TextBlock',
  props: {
    title: String,
    text: String,
    cssClass: String,
    bookOfClues: {
      type: Boolean,
      default: false
    }
  },
  components: { Img },
  computed: {
    mainTheme () {
      if (this.$props.cssClass) {
        return `help_him__cta-${this.$props.cssClass}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.help_him__cta {
  background: rgba(91,13,10,0.7);
  border-radius: 15px;
  display: flex;
  padding: vr(2) vr(1);
  @media(min-width: $md) {
    padding: vr(4) vr(3);
  }
  flex-direction: column;
  h1 {
    @include h1;
    margin-bottom: vr(2);
  }
  height: auto;
  align-self: center;
  .text__copy {
    font-size: vr(1);
    line-height: vr(1.5);
    @media(min-width: $md) {
      font-size: vr(1.1);
      line-height: vr(1.7);
    }
    p {
      margin-bottom: vr(2);
      border: solid 1px red;
    }
  }
  .text__download {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 10px 20px 10px 105px;
    display: inline-block;
    position: relative;
    margin-top: vr(2);
    a {
      @include link;
      @media(max-width: $sm-max) {
        font-size: vr(0.8);
      }
    }
    .book {
      position: absolute;
      width: 55px;
      left: 25px;
      top: -20px;
      transform: rotate(15deg);
      @media(max-width: $tb-max) {
        width: 50px;
        top: -30px;
      }
      @media(max-width: $sm-max) {
        top: -5px;
      }
    }
  }
  &.help_him__cta-blue {
    color: rgb(255, 255, 255);
    // background: rgba(13, 68, 75, 0.7);
    background: rgba(51, 74, 52, 0.7);
  }
  &.help_him__cta-faqs {
    padding: vr(1) vr(1);
    @media(min-width: $md) {
      padding: vr(2) vr(2);
    }
    .text__title {
      @include normal;
      font-size: vr(0.9);
      line-height: vr(1.3);
      font-weight: $font-weight-bold;
      color: rgb(255, 255, 255);
      margin-bottom: vr(1);
    }
    .text__copy {
      @include normal;
      font-size: vr(0.9);
      color: rgb(255, 255, 255);
      line-height: vr(1.5);
      p {
        margin-bottom: vr(0.5);
      }
      a {
        color: map-get($brand-colors, heading);
        color: red;
      }
    }
    &.faqs_blue {
      color: rgb(255, 255, 255);
      // background: rgba(13, 68, 75, 0.7);
      background: rgba(51, 74, 52, 0.7);
      .text__title {
        @include h1;
        margin-bottom: vr(1);
        @media(max-width: $tb-max) {
          font-size: vr(1.8);
        }
        @media(min-width: $md) {
          font-size: vr(2);
        }
      }
    }
  }
}
</style>
