<template>
  <div class="home">
    <Nav />
      About
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
  name: 'home',
  components: {
    Nav
  },
};
</script>
