<template>
  <section class="nav">
    <div class="nav__wrapper">
      <router-link @click.native="handleBurgerClick" to="/">
        <Vector class="svg logo ieFallup" src="logo.svg" />
        <Img class="svg logo ieFallback" src="logo.png" />
      </router-link>

      <nav :class='`nav__primary ${navCss}`'>
        <!-- <router-link @click.native="handleBurgerClick" to="/">About</router-link> -->
<!--        <router-link @click.native="handleBurgerClick" to="/covid-19">COVID-19</router-link>-->
<!--        <router-link @click.native="handleBurgerClick" to="/travel">Travel Info</router-link>-->
        <!-- <router-link @click.native="handleBurgerClick" to="/faqs">FAQs</router-link> -->
        <!-- <router-link @click.native="handleBurgerClick" to="/competition">Competition</router-link> -->
        <!-- <router-link @click.native="handleBurgerClick" to="/offers">Offers</router-link> -->
      </nav>

      <a href="https://www.sheffieldbid.com" target="_blank">
        <Vector class="svg bid ieFallup" src="bidV2.svg" />
        <Img class="svg bid ieFallback" src="bid.png" />
      </a>

      <Hamburger :checked="burgerChecked" @click.native="handleBurgerClick" class="nav__burger" style="opacity: 0;"/>
    </div>
  </section>
</template>

<script>
import Vector from '@/components/Vector'
import Img from '@/components/Img'
import Hamburger from '@/components/Hamburger'
export default {
  name: 'Nav',
  components: {
    Vector, Hamburger, Img
  },
  data () {
    return {
      burgerChecked: false
    }
  },
  methods: {
    handleBurgerClick () {
      console.log('burger click')
      this.burgerChecked = !this.burgerChecked
    }
  },
  computed: {
    navCss () {
      return this.burgerChecked ? 'nav__primary-active' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@include ieFallback;
.nav {
  padding-top: vr(1);
  padding-bottom: vr(1);
  @media(min-width: $md) {
    padding-top: vr(1);
    padding-bottom: vr(1);
  }
  &__wrapper {
    @include gridWidth;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__primary {
    @include heading;
    letter-spacing: -5px;
    align-self: center;
    font-size: vr(1.75);
    width: 53%;
    // justify-content: center;
    margin-right: auto;
    a {
      @include link;
      color: map-get($brand-colors, link);
      &:hover {
        @include linkHover;
      }

      @media(min-width: $md) {
        margin: 0 20px;
        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
    display: none;
    @media(min-width: $md) {
      display: flex;
      width: 65%;
      font-size: vr(1.5);
    }
    @media(min-width: $lg) {
      width: 53%;
      font-size: vr(1.75);
    }
    @media(max-width: $tb-max) {
      @include textureback('texture_red_3840.jpg', false)
      position: fixed;
      z-index: 900;
      top: -100%;
      left: 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
      padding-left: 25px;
      a {
        color: #fff;
        transform: translateX(-100%);
        transition: transform 300ms ease-in-out;
        &:nth-child(1) {
          transition-delay: 200ms;
        }
        &:nth-child(2) {
          transition-delay: 300ms;
        }
        &:nth-child(3) {
          transition-delay: 400ms;
        }
        &:nth-child(4) {
          transition-delay: 500ms;
        }
        &:nth-child(5) {
          transition-delay: 600ms;
        }
      }
      transition: top 300ms ease-in-out;
    }
    &-active {
      top: 0px;
      a {
        transform: translateX(0px);
      }
    }
  }
  &__burger {
    align-self: center;
    width: 30px;
  }
  .svg {
    width: 90px;
    @media(min-width: $md) {
      width: 120px;
    }
    @media(min-width: $lg) {
      width: 150px;
    }
    &.logo {
      @media(min-width: $md) {
        margin-right: vr(1.5);
      }
      @media(min-width: $lg) {
        margin-right: vr(4);
      }
      margin-bottom: 0;
    }
    &.bid {
      align-self: center;
      fill: white;
      @media(min-width: $md) {
        margin-left: unset;
        margin-left: auto;
      }
    }
  }
}
</style>
