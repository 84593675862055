<template>
  <div class="logo_and_bid">
    <Vector src="logo.svg" class="logo ieFallup" />
    <Vector src="bidV2-white.svg" class="logo bid ieFallup" />
    <Img src="logo.png" class="logo ieFallback" />
    <Img src="2021/sb-logo-white.png" class="logo bid ieFallback" />
  </div>
</template>

<script>
import Vector from '@/components/Vector.vue'
import Img from '@/components/Img.vue'
export default {
  name: 'LogoAndBid',
  components: { Vector, Img },
}
</script>

<style lang="scss" scoped>
@include ieFallback;
  .logo {
    margin-bottom: vr(0.5);
    @media(min-width: $md) {
      margin-bottom: vr(1);
    }
  }
  .bid {
    fill: #fff;
    margin-bottom: 0;
  }

  .logo_and_bid {
    display: flex;
    gap: 1rem;
  }
</style>
