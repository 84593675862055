<template>
  <section class="help_him">
    <div class="help_him__wrapper">
      <TextBlock class="help_him__cta" v-in-viewport.once
        title="" cssClass="blue"
        text="<p>Hidden letters are located at six points in the city centre. Two outdoors and four at larger retail venues, all of which take your safety very seriously with COVID-secure measures in place. At our four indoor venues you can follow Brearley’s paw prints to lead you to the hidden letters in store. We understand however that you might not want to enter premises at this time. To address these concerns, the hidden letters can also be found on the windows of our participating retailers.</p>
        <p>If you opt to go inside for the full experience, our venues are accessible between at least 9.30am and 5.30pm Monday to Saturday and 11am and 4pm Sunday. Venue trading times may differ on Christmas Eve. </p>" />

    </div>
  </section>
</template>

<script>
  import Vector from '@/components/Vector.vue'
  import Img from '@/components/Img.vue'
  import TextBlock from '@/components/TextBlock.vue'
  export default {
    name: 'HelpHim',
    components: {
      Vector,
      TextBlock,
      Img
    }
  }

</script>

<style lang="scss" scoped>
  .help_him {
    color: #fff;
    position: relative;
    display: flex;
    margin-top: 100px;

    &__wrapper {
      @include gridWidth;
      display: flex;
      height: 100%;
      flex-direction: column-reverse;

      @media(min-width: $md) {
        align-self: center;
        place-content: center;
        flex-direction: row;
      }
    }

    .fox_svg {
      align-self: center;
      width: 40%;
      padding-top: 40px;
      padding-bottom: 40px;

      @media(min-width: $md) {
        padding: 0;
        width: 20%;
      }

      @include slideIn($fromRight: false);
    }

    &__cta {
      @media(min-width: $md) {
        margin: 0 auto;
        width: 60%;
      }

      @include slideIn();
    }

    .owl_svg {
      width: 150px;
      z-index: 200;
      transform: translateX(1px);
      position: absolute;
      right: 30%;
      top: -140px;
      display: none;

      @media(min-width: $md) {
        display: block;
      }
    }
  }

</style>
