<template>
  <div class="travel">
    <TextureWrapper cssStyle="pink">
      <Nav />
      <div class="travel__wrapper">
        <div class="travel__copy">
          <h1 class="travel__title">
            Travel Information
          </h1>

          <TextBlock
            cssClass="faqs faqs_blue"
            title="By car"
            text="<p>Get a 10% discount at the following Q-Park venues when you book online. Use the promo code SHEFFXMAS10 for:</p>
            <ul>
              <li>Rockingham Street</li>
              <li>Sheffield Station</li>
              <li>Charles Street</li>
            </ul>
            <p>Get a 25% discount at the following Q-Park venues when you book online. Use the promo code SHEFFXMAS25 for:</p>
            <ul>
              <li>Durham Road</li>
              <li>Riverside</li>
              <li>Castlegate</li>
            </ul>
            <p>Pre-book car parking at <a href='https://www.q-park.co.uk/en-gb/cities/sheffield/'>Q Park Sheffield</a> to receive your discount.</p>"/>
          <TextBlock
            cssClass="faqs faqs_blue"
            title="NCP"
            text='<p>The NCP Park Pass is available to use at any NCP car park in Sheffield: £2.50 for two hours or £5.00 for up to 24 hours. Search the app store or Google Play for NCP Park Pass to access this exclusive parking saver deal.</p>'
          />
          <TextBlock
            cssClass="faqs faqs_blue"
            title="Council Car Parks"
            text='<p>Three free weekends! Sheffield City Council will be providing free parking Saturdays and Sundays in any of its car parks or on-street pay and display bays in the run up to Christmas. This free parking offer begins Saturday, 5 December until Sunday, 20 December. This free parking complements the low prices offered year-round in the city centre. You can also park your car in a council car park or use an on-street parking space after 4.30pm on weekdays and in most cases it will cost less than two pounds.</p>
            <p>Visit the council website to find out more <a href="https://www.sheffield.gov.uk/home/parking/city-centre-car-parking">here.</a></p>'
          />
          <TextBlock
            cssClass="faqs faqs_blue"
            title="Travel South Yorkshire"
            text='To plan your journey for the Sheffield Christmas Trail, use Travel South Yorkshire’s fantastic travel planner. For any bus, tram and train journey, just <a href="https://tsy.yorkshiretravel.net/lts/#/travelInfo
">click here.</a>'
          />
          <TextBlock
            cssClass="faqs faqs_blue"
            title="Active travel"
            text="<p>For those wanting to travel to Sheffield Christmas Trail by bike, head to Sheffield City Council's <a href='https://www.sheffield.gov.uk/content/sheffield/home/travel-transport/cycle-routes-schemes.html'>website</a> for details of cycle routes.</p>
            <p>Need a bike? You can hire a bike from Russell’s Bike Shed at Sheffield Train Station from £5 a day. Find more details on the <a href='https://www.russellsbicycleshed.co.uk/cycle-hire'>website.</a></p>"
          />

        </div>
      </div>
    </TextureWrapper>
    <TextureWrapper cssStyle="">
      <Footer />
    </TextureWrapper>
  </div>
</template>

<script>
import TextureWrapper from '@/components/TextureWrapper'
import TextBlock from '@/components/TextBlock'
import GetApp from '@/components/GetApp'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer'

export default {
  name: 'home',
  components: {
    Nav, GetApp, TextureWrapper, Footer, TextBlock
  },
};
</script>

<style lang="scss" scoped>
.travel {
  color: rgb(255, 255, 255);
  &__wrapper {
    z-index: 2;
    @include gridWidth;
    padding-top: 40px;
    @media(min-width: $md) {
      padding-top: 100px;
    }
  }
  &__copy {
    @media(min-width: $md) {
      width: 100%;
    }
    padding-bottom: vr(4);
  }
  &__title {
    @include heading;
    font-weight: normal;
    font-size: vr(3);
    line-height: vr(2);
    margin-bottom: vr(3);
  }
  &__copy {
    & > article {
      margin-bottom: vr(1.5);
      width: 100%;
      z-index: 300;
      @media(min-width: $md) {
        margin-bottom: vr(3);
        width: 80%;
        margin-left: auto;
        align-self: flex-end;
        &:nth-child(2n+2) {
          align-self: flex-start;
          margin-left: 0;
        }
      }
      @media(min-width: $lg) {
        width: 70%
      }
      @media(min-width: $xl) {
        width: 80%
      }
    }
  }
}
</style>
