<template>
  <div :class='`get_app get_app__${$props.cssStyle}`'>
    <h1 class="get_app__title">Get The App</h1>
    <a href="https://play.google.com/store/apps/details?id=io.blackeye.christmastrail">
      <Img class='get_app__svg get_app__google' src="google-play-badge.png" />
    </a>
    <a href="https://itunes.apple.com/us/app/sheffield-christmas-trail/id1436841953?ls=1&mt=8">
      <Img class="get_app__svg get_app__apple" src="app-store-badge.png" />
    </a>
  </div>
</template>

<script>
import Img from '@/components/Img'
import Vector from '@/components/Vector'
export default {
  name: 'GetApp',
  props: ['cssStyle'],
  components: { Img, Vector }
}
</script>

<style lang="scss" scoped>

.get_app {
  .comingsoon {
    position: absolute;
    color: white;
    border-radius: 15px;
    border: solid 8px white;
    padding: 10px 20px;
    box-shadow: 0px 0px 12px rgba(50, 50, 50, 0.6), inset 0px 0px 12px rgba(50, 50, 50, 0.6);
    text-shadow: 0px 0px 12px rgba(10, 10, 10, 0.8);
    transform: rotateZ(-10deg);
    font-size: 25px;
    background: rgba(255, 255, 255, 0.4);
    @media(min-width: $tb) {
      font-size: 40px;
      border-width: 10px;
      right: none;
    }
    @media(min-width: $md) {
      font-size: 50px;
      border-width: 10px;
      right: none;
    }
    @media(min-width: $lg) {
      right: 20px;
    }
  }
  &__blue {
    color: rgb(255, 255, 255);
    background: rgba(13, 68, 75, 0.7);
  }
  color: #0d444b;
  border-radius: 10px;
  padding: vr(1) vr(1.5);
  @media(min-width: $md) {
    padding: vr(1.5) vr(1.5);
  }
  @media(min-width: $lg) {
    padding: vr(1.5) vr(1.8);
  }
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  @media(min-width: $md) {
    flex-wrap: wrap;
    flex-direction: row;
  }
  @media(min-width: $lg) {
    flex-wrap: nowrap;
  }
  place-content: center;
  &__title {
    width: 85%;
    @include h1;
    text-align: center;
    font-size: vr(2);
    line-height: vr(1.5);
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    @media(min-width: $lg) {
      text-align: left;
      font-size: vr(3);
      line-height: vr(1.5);
      padding: 0;
      margin: 0;
    }
    align-self: center;
  }
  &__svg {
    margin-bottom: 10px;
    max-width: 280px;
    @media(min-width: $md) {
      margin-bottom: 0px;
      width: 140px;
    }
    &.get_app__google {
      @media(min-width: $md) {
        width: 160px;
        margin-right: 20px;
      }
    }
    @media(min-width: $xl) {
      margin-bottom: 0px;
      width: 180px;
    }
    &.get_app__google {
      @media(min-width: $xl) {
        width: 207px;
        margin-right: 20px;
      }
    }
  }
}
</style>
