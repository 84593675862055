<template>
  <section class="postoffice">
    <div class="postoffice__wrapper">
      <div class="postoffice__copy">
        <h1 class="postoffice__title">Santa’s Post Office</h1>
        <div class="postoffice__text">
          <p>
          Brearley Bear has written his Christmas list but have you sent yours to Santa yet? Head to Santa’s Post Office at 15 Barker’s Pool for a free festive experience this Christmas.
          </p>
          <p>
          There’s no need to book, families can pop in and write their letter to Santa before posting it in the magic post box which sends the letter all the way to Lapland.
          </p>
          <p>
          Santa’s Post Office opening times: <br />
          10am – 4pm on Saturdays and Sundays <br />
          4pm – 7pm on Thursdays from 17 November until 17 December <br />
          From 18 December – 24 December daily from 10am – 4pm
          </p>
        </div>
      </div>
      <div class="postoffice__santa">
        <Img src="santa.png" class="svg santa" v-in-viewport.once />
      </div>
    </div>
  </section>
</template>

<script>
import Vector from '@/components/Vector'
import Img from '@/components/Img'
export default {
  name: 'PostOffice',
  components: { Vector, Img }
}
</script>

<style lang="scss" scoped>
.postoffice {
  padding-top: vr(2);
  padding-bottom: vr(2);
  @media(min-width: $md) {
    padding-top: vr(4);
    padding-bottom: vr(4);
  }
  color: rgb(255, 255, 255);
  &__wrapper {
    @include gridWidth;
    display: flex;
    flex-direction: column;
    @media(min-width: $md) {
      flex-direction: row;
    }
  }
  &__title {
    text-transform: uppercase;
    @include heading;
    font-size: vr(2.5);
    line-height: vr(2);
    font-weight: normal;
  }
  &__copy {
    width: 100%;
    @media(min-width: $md) {
      width: 60%;
    }
  }
  &__santa {
    width: 40%;
    margin: 0 auto;
    margin-top: vr(2);
    @media(min-width: $md) {
      margin-left: auto;
      width: 18%;
    }
    .svg {
      @include slideIn;
    }
  }
}
</style>
