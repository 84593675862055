<template>
  <div class="home" style="line-height: 2rem;">

    <TextureWrapper cssStyle="green">
      <Nav />
      <Header />
      <!--      <HelpHim />-->
      <!--      <MoreInfo />-->
      <!--      <DatesTimes />-->
    </TextureWrapper>
    <div class="snow" style="margin-top: -4rem; overflow-x: hidden">
      <Img src="/snow-floor-big.png" style="width: 110%; margin-left: -4rem; margin-right: -4rem;" />
    </div>
    <div style="background: white; margin-top: -4rem; padding: 0 3rem 3rem 3rem">
      <div class="container">
        <div class="grid">
          <div class="mobile-padding-top">
            <h3 style="color: #244920; margin-bottom: 1.5rem">Can you help Brearley?</h3>
            <p>
              After so long without seeing any of his friends, Brearley Bear has decided to organise a Christmas party reunion. But oh dear, the party invitations have got all mixed up!
            </p>
            <p>
              He needs your help to find where to address his friends’ invitations.
            </p>
            <p>
              Follow the clues around the city centre. Find his friends and the venues they’re in so that Brearley can send out the right invitations.
            </p>
            <p>
              Once you’ve matched them all, take your list back to Brearley’s HQ so that he can get this party started!
            </p>
          </div>
          <div style="padding: 0 5rem; text-align: center">
            <Img src="/present.png" style="width: 100%"/>
          </div>
        </div>
      </div>
    </div>
    <div style="background: #DE444C; padding: 6rem 3rem; color: white">
      <div class="container">
        <div class="grid" style="gap: 4rem">
          <div>
            <p>
              The Sheffield Christmas Trail … Brearley Bear’s Christmas Party will run from Saturday, 27 November to Friday, 24 December 2021. Track down eight of Brearley’s friends by following the rhyming clues then head back to Brearley’s Christmas HQ to receive a free Christmas treat for every child.
            </p>
            <p>
              Eight of Brearley’s friends are hiding at venues in the city centre. Venues are accessible between at least 9.30am and 5.00pm Monday to Saturday and 11.00am and 4.00pm Sunday. Venue trading times may differ on Christmas Eve but all are open until at least 3.00pm.
            </p>
            <p>
              Brearley’s HQ can be found at Unit 1, Surrey Street (next to the Winter Garden). Opening times are:
            </p>
            <ul>
              <li>
                10.00am – 5.00pm Monday – Saturday (7.00pm Thursday)
              </li>
              <li>
                11.00am – 4.00pm Sunday, and
              </li>
              <li>
                10.00am – 4.00pm Christmas Eve
              </li>
            </ul>
            <p>
              Brearley’s HQ will be closed from 12 noon on Wednesday, 8 December. On Christmas Eve it also closes at 4.00pm prompt so please allow enough time to finish the trail and collect the children’s special gift!
            </p>
            <p>
              Download the trail guide or pick up one from Brearley’s Christmas HQ on Surrey Street (next to the Winter Garden).
            </p>
            <p>
              Track down eight of Brearley’s friends by following the rhyming clues then head back to Brearley’s Christmas HQ to receive a free Christmas treat for every child.
            </p>
            <p>
              <a href="/SheffieldChristmasTrail2021.pdf" class="btn" style="background-color: #98CEB2; border: 0; color: #254920; width: auto">DOWNLOAD TRAIL GUIDE</a>
            </p>
          </div>
          <div>
            <h3 style="margin-bottom: 1.5rem">Helpful Hints</h3>
            <p>
              Here’s a few helpful hints to help you manage your trail experience …
            </p>
            <p>
              Millennium Gallery is a trail venue which is closed Mondays and Christmas Eve – the “friend” you’re looking for here is still visible from the Arundel Gate front entrance.
            </p>
            <p>
              At many of our trail venues you’ll find wayfinding footprints in place to help you (although we are limited in some venues so you might need to explore a bit more in some places). Here’s a helpful hint about the “friend” you’re looking for at M&S – he’s on the 2nd floor (near the Bureau De Change)!
            </p>
            <Img src="/tree.png" style="display: block; margin: 0 auto; width: 100%; max-width: 340px;" />
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative; background: white">
      <Img src="/SheffBID.png" class="responsive-bg" />
      <div class="container" style="z-index: 1; position: relative; padding: 3rem;">
        <div class="grid">
          <div></div>
          <div style="background: white; color: #1B2442; padding: 2rem 1.5rem">
            <h3 style="margin-bottom: 1.5rem">The magic of Christmas in Sheffield city centre</h3>
            <p>
              If you’re taking part in the Sheffield Christmas Trail featuring Brearley Bear, then why not linger longer. Revel in the magical sights, sounds and tastes of Christmas in Sheffield city centre. All kinds of festive encounters await you this winter. To find out more about the festive highlights please head over to
              <a href="https://www.sheffieldcitycentre.com/xmas">www.sheffieldcitycentre.com/xmas</a>
            </p>
            <p>
              Here you’ll also find information on public transport offers, festive car parking deals and local restaurants and eateries offering a “kids eat free” promotion for Brearley Bear trail goers.
            </p>
            <p>
              Merry Christmas!
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--    <TextureWrapper cssStyle="">-->
    <Footer />
    <!--    </TextureWrapper>-->
  </div>
</template>

<style lang="scss">
.container {
  @include gridWidth;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr;
  }

  > div {
    width: 100%;
  }
}

.mobile-padding-top {
  padding-top: 4rem;

  @media (min-width: $md) {
    padding-top: 0;
  }
}

.responsive-bg {
  top: 0;
  left: 0;
  right: 0;
  height: 380px;
  object-fit: none;

  @media (min-width: $md) {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0
  }
}
</style>

<script>
import TextureWrapper from '@/components/TextureWrapper.vue'
import Nav from '@/components/Nav.vue'
import Header from '@/components/Header.vue'
import HelpHim from '@/components/HelpHim.vue'
import MoreInfo from '@/components/MoreInfo.vue'
import DatesTimes from '@/components/DatesTimes.vue'
import PostOffice from '@/components/PostOffice.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'home',
  components: {
    TextureWrapper, Header, HelpHim, DatesTimes, Nav, PostOffice, Footer, MoreInfo
  },
};
</script>
