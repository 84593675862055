<template>
  <section class="help_him">
    <div class="help_him__wrapper">
      <Img src="2020/acorns.png" class="fox_svg" v-in-viewport.once />
      <TextBlock class="help_him__cta" v-in-viewport.once
        title="Follow the clues around the city centre to find out just who it is!" cssClass="blue"
        :bookOfClues="true"
        text="<p>We’ve given you two letters, now follow the rhyming clues to find the rest and you’ll soon uncover Brearley’s Festive Guest!</p>
              <p>Once you’ve solved the riddle, head back to Brearley’s Christmas HQ to receive a free Christmas treat for every child.</p>
              <p>Download the trail guide here or pick up one from Brearley’s Christmas HQ on Surrey Street (next to the Winter Garden)</p>" />

    </div>
  </section>
</template>

<script>
  import Vector from '@/components/Vector.vue'
  import Img from '@/components/Img.vue'
  import TextBlock from '@/components/TextBlock.vue'
  export default {
    name: 'HelpHim',
    components: {
      Vector,
      TextBlock,
      Img
    }
  }

</script>

<style lang="scss" scoped>
  .help_him {
    color: #fff;
    position: relative;
    display: flex;
    margin-top: 100px;

    &__wrapper {
      @include gridWidth;
      display: flex;
      height: 100%;
      flex-direction: column-reverse;

      @media(min-width: $md) {
        align-self: center;
        place-content: center;
        flex-direction: row;
      }
    }

    .fox_svg {
      align-self: center;
      width: 40%;
      padding-top: 40px;
      padding-bottom: 40px;

      @media(min-width: $md) {
        padding: 0;
        width: 20%;
      }

      @include slideIn($fromRight: false);
    }

    &__cta {
      @media(min-width: $md) {
        margin-left: auto;
        width: 60%;
      }

      @include slideIn();
    }

    .owl_svg {
      width: 150px;
      z-index: 200;
      transform: translateX(1px);
      position: absolute;
      right: 30%;
      top: -140px;
      display: none;

      @media(min-width: $md) {
        display: block;
      }
    }
  }

</style>
