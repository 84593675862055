<template>
  <section class="headder">
    <div class="header__content" style="text-align: center">
      <div class="header__logos">
        <div class="christmastrail">
          <div class="grid">
            <Img src="2021/title-block+date.png" :class="`${viewportClass}`" />
            <Img src="2021/brearley.png" :class="`${viewportClass}`" />
          </div>
        </div>
      </div>
<!--      <div class="bear_content">-->
<!--        <div class="left">-->
<!--          <p :class="`copy ${viewportClass}`">Make yourself at home this Christmas in Sheffield city centre!</p>-->
<!--          <p :class="`copy ${viewportClass}`">Brearley Bear is back for Christmas 2020. And this time he has a special festive guest coming for Christmas.</p>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <Img src="2020/brearley.png" :class="`bear_svg ${viewportClass}`" />-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </section>
</template>
<script>
import Vector from '@/components/Vector.vue'
import Img from '@/components/Img.vue'
export default {
  name: 'Header',
  components: { Vector, Img },
  data () {
    return {
      viewportClass: ''
    }
  },
  mounted () {
    this.viewportClass = 'in-viewport'
  }
}
</script>
<style lang="scss" scoped>

.header {
  padding-top: vr(2);
  @media(min-width: $md) {
    min-height: 90vh;
  }
  &__content {
    @include gridWidth;
  }
  .bear_content {
    padding: vr(0.75) vr(0);
    @media(min-width: $md) {
      padding: vr(2.5) vr(1);
    }
    color: #fff;
    font-size: vr(1);
    @media(min-width: $md) {
      font-size: vr(1.5);
    }
    display: flex;
    flex-direction: column;
    @media(min-width: $md) {
      flex-direction: row;
    }
    .get_app {
      @include slideIn($fromRight: false, $delay: 200ms, $time: 750ms);
    }
    .copy {
      @include slideIn($fromRight: false);
      margin-bottom: vr(1.5);
      @media(min-width: $md) {
        margin-bottom: vr(4);
      }
    }
    .left {
      @media(min-width: $md) {
        width: 75%;
        padding-right: 5%;
      }
    }
    .right {
      width: 60%;
      margin: 0 auto;
      margin-top: 20px;
      @media(min-width: $md) {
        width: 25%;
        .bear_svg {
          margin-top: -80px;
          @include slideIn();
        }
      }
    }
    .download {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      img, svg {
        max-width: 200px;
      }
    }
  }
  &__logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .christmastrail {
      @media(min-width: $md) {
        width: 65%;
      };
      .christmastrail-svg {
        @media(min-width: $md) {
          min-width: 501px;
        }
        //@include slideIn($fromRight: false);
      }
    }
  }
}

</style>
