<template>
  <div id="app" ref="app">
    <transition name="fade">
      <router-view/>
    </transition>
<!--    <Parallax ref="pfeet" class="feet" :speedFactor="0.3">-->
<!--      <Vector src="feet.svg" ref="feet" />-->
<!--    </Parallax>-->
  </div>
</template>

<script>
import Vector from '@/components/Vector'
import Parallax from "vue-parallaxy";

export default {
  name: 'App',
  components: { Vector, Parallax },
  metaInfo: {
    title: 'Sheffield Christmas Trail',
    titleTemplate: '%s | Sheffield Christmas Trail',
  },
  updated() {
    console.log('updated')
    this.resizeFeet()
  },
  mounted () {
    console.log('mounted')
    this.resizeFeet()
  },
  data () {
    return {
      appClientHeight: 0,
      feetEl: null
    }
  },
  methods: {
    resizeFeet () {
      setTimeout(() => {
        console.log('resize feet')
        this.appClientHeight = this.$el.clientHeight
        this.feetEl = document.getElementsByClassName('feet')[0]
        this.feetEl.height = this.appClientHeight + 'px'
        this.feetEl.style.height = this.appClientHeight + 'px'
        console.log('app client height: ', this.$el.clientHeight)
        console.log('feet el', this.feetEl)
        console.log('feet el height', this.feetEl.height)
        console.log('feet el style height', this.feetEl.style.height)
      }, 500)
    }
  }
};
</script>

<style lang="scss">
.Masthead {
  width: 100vw;
  z-index: 1 !important;
  position: absolute !important;
  top: 0 !important;
}
.feet {
  position: absolute;
  top: 0;
  z-index: 1;
  opacity: 0.25;
  display: none;
  @media(min-width: $md) {
    @supports (grid-area: auto) {
      display: block;
    }
  }
}
#app {
  overflow-y: hidden;
}
body, h1, h2, h3, h4, h5, a, ul, ol, li, p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  background-color: #ACCBB7;
  background-size: cover;
  color: #02454c;
  font-family: $fjalla, sans-serif;
  font-size: 17px;
  line-height: 1.5;
}
* {
  box-sizing: border-box;
}
body {
  @include normal;
  font-weight: $font-weight-regular;
  color: $text-grey;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // &::after {
  //   content: 'XSmall';
  //   position: fixed;
  //   bottom: 0;
  //   right: 0;
  //   background-color: rgb(200,200,200);
  //   opacity: 0.4;
  //   padding: vr(0.5);
  //   z-index: 100;
  //   @media (min-width: $sm) {
  //     content: 'Small';
  //   }
  //   @media (min-width: $tb) {
  //     content: 'Tablet';
  //   }
  //   @media (min-width: $md) {
  //     content: 'Medium';
  //   }
  //   @media (min-width: $lg) {
  //       content: 'Large';
  //   }
  //   @media (min-width: $xl) {
  //     content: 'X-Large';
  //   }
  //   @media (min-width: $xxl) {
  //     content: 'Huge';
  //   }
  // }
}
p {
  margin-bottom: vr(1);
  &:last-child {
    margin-bottom: 0;
  }
}
a {
  @include link;
}
.link--arrow {
  position: relative;
  padding-right: vr(1);
  &::after {
    content: '>';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: map-get($brand-colors, heading);
    font-size: vr(1.5);
    margin-left: vr(0.5);
  }
}
.fade-enter-active, .fade-leave-active {
  transition-property: all;
  transition-duration: .2s;
}

.fade-enter-active {
  transition-delay: .2s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
  transform: translateX(-50px);
}
</style>
